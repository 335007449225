import { DefaultInput, FormErrors, ModalForm } from 'apptimizm-ui'
import { Ref } from 'vue'
import { Product } from './types'

interface ModalProps {
  toggle: () => void,
  product: Ref<Product>,
  formErrors: Ref<FormErrors>
  submit: () => void
}

export default ({ toggle, submit, product, formErrors }: ModalProps) => {
  return (
    <ModalForm
      cancel={() => toggle()} confirm={() => {}}
      header="Редактирование"
      slots={{
        default: () => (
          <div class="mt-4">
            <label>
              <DefaultInput
                class={product.value.title ? 'input-placeholder-up' : ''}
                modelValue={product.value.title}
                onValueChange={(v: string) => { product.value.title = v } }
                placeholder="Наименование товара"
                errors={formErrors.value.forField('title')}
                disabled={true}
              />
            </label>
            <label class="mt-2">
              <DefaultInput
                class={product.value.vendorCode ? 'input-placeholder-up' : ''}
                modelValue={product.value.vendorCode}
                onValueChange={(v: string) => { product.value.vendorCode = v } }
                placeholder="Код товара"
                errors={formErrors.value.forField('vendorCode')}
                disabled={true}
              />
            </label>
            <div class="row mt-2">
              <label>
                <DefaultInput
                  class={product.value.remainder ? 'input-placeholder-up' : ''}
                  modelValue={product.value.remainder.toString()}
                  onValueChange={(v: string) => { product.value.remainder = +v } }
                  placeholder="Остаток"
                  errors={formErrors.value.forField('remainder')}
                  disabled={true}
                />
              </label>
              <label>
                <DefaultInput
                  class={product.value.price ? 'input-placeholder-up' : ''}
                  modelValue={product.value.price}
                  onValueChange={(v: string) => { product.value.price = v } }
                  placeholder="Цена товара"
                  errors={formErrors.value.forField('price')}
                  disabled={true}
                />
              </label>
            </div>
          </div>
        ),
        buttons: () => (
          <button disabled={true} onClick={submit} class="primary-button mt-4">Сохранить</button>
        )
      }}
    />
  )
}
